.filter-wrapper .tags .active,
.filter-wrapper .mobile-filter {
  color: #209cee;
  border-color: #209cee;
}
.filter-wrapper .tags li {
  cursor: pointer;
}
.update-list .post {
  background: #fafafa;
}
.filter-active .tags {
  display: block;
  position: absolute;
}
.filter-active .tags .active {
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
  border-color: #e5e7eb;
}
.pagination .rounded {
  border-radius: 6px !important;
}
.pagination .btn {
  width: 40px;
  height: 40px;
  min-height: 40px;
}
.search-input{
  background: #FFFFFF;
  border: 1px solid #4A4A4A;
  border-radius: 4px;
  color: #4A4A4A;
  padding: 6px 12px;
}
.search-input::-webkit-input-placeholder { /* Edge */
  color: #4A4A4A;
  opacity: 1;
}
.search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4A4A4A;
  opacity: 1;
}
.search-input::placeholder {
  color: #4A4A4A;
  opacity: 1;
}