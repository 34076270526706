@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&family=Montserrat:wght@200;300;400&display=swap');
@font-face {
    font-family: 'aceh';
    src: url('fonts/aceh-bold-webfont.woff2') format('woff2'),
        url('fonts/aceh-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'aceh';
    src: url('./fonts/aceh-light-webfont.woff2') format('woff2'),
        url('./fonts/aceh-light-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'aceh';
    src: url('./fonts/aceh-medium-webfont.woff2') format('woff2'),
        url('./fonts/aceh-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../node_modules/ckeditor5/dist/ckeditor5.css';

.ck {
    .ck-content {
        @apply prose;
        .table {
            @apply w-full;
        }
    }

    .ck-balloon-panel,
    .ck-toolbar,
    .ck-dialog {
        @apply bg-white;
    }
}

.prose {
    .table {
        @apply w-full;
    }
}

@layer base {
    :root {
        --bg-color: #ffffff;
        --sidebar: #09a5ae;
        color: #363636;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #0a0a0a;
}

.align-items-center {
    align-items: center;
}

.form-input {
    @apply block w-full px-1 py-2 rounded-lg my-1 bg-gray-900 text-gray-50;
}
.form-btn {
    @apply bg-blue-700 hover:bg-blue-800 transition duration-300 w-full text-center py-2 my-4 rounded-lg font-semibold;
}
.desk-nav {
    @apply hover:bg-gray-900 py-1.5 px-3 rounded-lg transition duration-200;
}
.desk-nav-active {
    @apply bg-gray-900 hover:bg-gray-700 py-1.5 px-3 rounded-lg transition duration-200;
}
.mobile-nav {
    @apply block hover:bg-gray-600 transition duration-300 bg-gray-700 mx-2 py-1.5 rounded-lg;
}
.mobile-nav-active {
    @apply block hover:bg-gray-600 transition duration-300 bg-gray-900 mx-2 py-1.5 rounded-lg;
}
.dash-side-nav {
    @apply block bg-gray-900 hover:bg-gray-700  px-5 py-3 rounded-lg shadow-lg text-gray-50 transition duration-300;
}
.dash-content {
    @apply mt-5 bg-gray-700 p-5 rounded-lg shadow-lg;
}
.dash-mb-side {
    @apply block bg-gray-900 hover:bg-gray-700  px-3 py-2 mx-2 rounded-lg shadow-lg text-gray-50 transition duration-300;
}

.sidebar-container {
    z-index: 999;
}
.top-bar,
.sidebar-container {
    background-color: var(--sidebar);
}

.top-bar {
    position: relative;
}

.top-bar > div {
    position: relative;
    z-index: 1;
}

body {
    background-color: var(--bg-color);
}

.badge.bg-warning {
    color: #947600;
}

.badge.bg-secondary,
.btn.btn-secondary {
    color: #4a4a4a;
}

.btn.btn-secondary:hover {
    color: #ffffff;
}

.btn-blue {
    border: 1px solid #02c2ff !important;
    border-radius: 2px !important;
    color: #02c2ff !important;
}

.btn-blue:hover {
    background-color: #02c2ff !important;
    color: #fff !important;
}

.side-card {
    background: rgba(250, 250, 250, 0.1);
    border-radius: 5px;
}

.avatar:hover .hover {
    display: grid;
}

textarea {
    border: 1px solid #dbdbdb;
    border-radius: 6px;
}
.status {
    color: #000;
}
.justify-between {
    justify-content: space-between;
}
.warning-cta-text {
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #947600;
}
.cols-1fr-auto {
    grid-template-columns: 1fr 50px;
}
.justify-end {
    justify-content: end;
}
.icon-dropdown {
    display: flex;
}
.icon-dropdown svg,
.file-upload-wrapper svg {
    width: 30px;
    height: 30px;
}
.icon-dropdown select {
    appearance: none;
}
.file-upload-wrapper p {
    display: flex;
    align-items: center;
}
.file-upload-wrapper p svg {
    width: 50px;
    height: 50px;
}
.rdt_TableHeadRow {
    border-bottom-color: #d9dadb !important;
}
.table-status {
    display: grid;
    grid-template-columns: 40px 1fr;
    column-gap: 15px;
}
.table-status svg {
    width: 100%;
}
@media screen and (max-width: 992px) {
    .sidebar-container {
        transform: translateX(100%);
    }

    .top-bar::before {
        position: absolute;
        content: '';
        background-color: var(--sidebar);
        margin-top: -1.25rem;
        margin-left: -1.25rem;
        width: 100vw;
        height: calc(100% + 2.5rem);
    }

    .menu-active .sidebar-container {
        transform: translateX(0);
    }
}

.rbc-row-segment .rbc-event-content {
    white-space: pre-wrap;
}
