.project-progress-bar {
  background: #F5F5F5;
  border-radius: 10px;
}

.project-progress-bar .current,
.project-progress-bar .next {
  padding: 7px 15px;
  border-radius: 10px;
  color: #fff;
}

.project-progress-bar .next {
  color: #0A0A0A;
}

.project-progress-bar .flex {
  align-items: center;
}

.project-progress-bar .text-2xl {
  line-height: 20px;
}
@media screen and (max-width: 767px) {
  .project-progress-bar .current {
    min-width: 90px !important;
  }
}