.react-calendar{
  border: 0;
  width: 100%;
}
.react-calendar .react-calendar__navigation{
  font-weight: bold;
}
.react-calendar button.react-calendar__tile{
  position: relative;
  font-size: 14px;
}
.react-calendar button.react-calendar__tile:hover{
  background-color: transparent !important;
}
.react-calendar button.react-calendar__tile::after{
  display: block;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.react-calendar button.react-calendar__tile:hover::after{
  background-color: #e6e6e6;
}
.react-calendar__tile--active:enabled:focus{
  background-color: transparent;
}
.react-calendar button.react-calendar__tile--active::after{
  background-color: black !important;
}
.react-calendar button.react-calendar__tile abbr{
  position: relative;
  z-index: 2;
}
.react-calendar__month-view__weekdays abbr{
  text-decoration: none;
}
.react-calendar__navigation__label{
  text-transform: uppercase;
}
.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: #56a2a9;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #244041;
}
